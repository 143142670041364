<template>
    <ka-auth-page>
        <form @submit.prevent="submit" class="page-content page-grid">
            <h1 class="tw-heading-1">{{ $t('auth.actions.sign_in_simple') }}</h1>

            <ka-sign-in-with-providers />

            <div class="form-inputs">
                <k-input
                    v-model="email"
                    :title="$t('global.inputs.email')"
                    type="email"
                    name="username"
                    autocomplete="username"
                    :emptyNotice="submitted"
                />

                <div class="mt-4 password-field">
                    <k-password
                        :title="$t('global.inputs.password')"
                        v-model="password"
                        :emptyNotice="submitted"
                        name="current-password"
                        autocomplete="current-password"
                        :placeholder="$t('global.form_rules.length_6')"
                    />

                    <router-link :to="{ name: 'auth-password', params: { locale } }" class="forgot-password-link kod-link">
                        {{ $t('auth.actions.forgot_password') }}
                    </router-link>
                </div>

                <k-checkbox v-model="remember" class="mt-4" :label="$t('auth.inputs.remember_login')" />
            </div>

            <v-expand-transition>
                <div v-show="signInStatus.error" class="form-error-message">
                    <p>{{ $t(signInStatus.error) }}</p>
                </div>
            </v-expand-transition>

            <div>
                <k-button
                    color="primary"
                    :block="isMobile"
                    :loading="signInStatus.isLoading"
                    :success="signInStatus.isSuccess"
                    :disabled="signInStatus.isLoading"
                    type="submit"
                >
                    {{ $t('auth.actions.sign_in') }}
                </k-button>
            </div>

            <p>
                {{ $t('auth.descriptions.dont_have_account') }}
                <router-link :to="{ name: 'auth-signup', params: { locale } }" class="kod-link-primary">
                    {{ $t('auth.actions.create_new_account') }}
                </router-link>
            </p>
        </form>
    </ka-auth-page>
</template>

<script>
import KaAuthPage from "./ka-auth-page";
import responsiveMixin from "./../responsiveMixin";
import KaSignInWithProviders from "./ka-sign-in-with-providers";
import { getRouteLocale } from '@web/constants/language'

export default {
    mixins: [responsiveMixin],
    components: {
        KaAuthPage,
        KaSignInWithProviders
    },
    data() {
        return {
            email: null,
            password: null,
            remember: true,
            showPassword: false,
            submitted: false
        };
    },
    computed: {
        locale() {
            return getRouteLocale()
        },
        signInStatus() {
            return this.$store.getters["AUTH/getStatus"]('signInStatus')
        }
    },
    methods: {
        isFormValid: function() {
            return this.email && this.password;
        },
        submit() {
            this.submitted = true;

            if (!this.isFormValid()) {
                return;
            }
            this.$store.dispatch('AUTH/signIn', {
                email: this.email,
                password: this.password,
                remember: this.remember
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.page-content {
    width: 22.5rem;
}

.page-grid {
    display: grid;
    gap: 2rem;
}

.password-field {
    position: relative;
}

.forgot-password-link {
    color: $koderia-black;
    position: absolute;
    top: 0;
    right: 0;
}

.form-inputs {
    position: relative;
    margin-top: -1.25rem;
}

.form-error-message p {
    margin-top: 0 !important;
}
</style>
